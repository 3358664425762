<template>
  <TemplateHeader :header="'Checkout'">
    <v-tabs v-model="tab" align-with-title active-class="mySecondary--text">
      <v-tabs-slider color="myPrimary"></v-tabs-slider>

      <v-tab v-for="item in items" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <TableEmas
          :tableLoading="tableLoadingEmas"
          :dataEmas="dataEmas"
          :headersEmas="headersEmasCheckout"
          :tableEdit="true"
          :tableDelete="true"
          :sortBy="dataEmas.id"
          :totalKeuntunganEmas="totalKeuntunganEmas"
          @filterEmas="getProductsGold"
          :dataPagination="dataPaginationEmas"
        />
      </v-tab-item>
      <v-tab-item>
        <TableBerlian
          :tableLoading="tableLoadingBerlian"
          :dataBerlian="dataBerlian"
          :headersBerlian="headersBerlianCheckout"
          :tableEdit="true"
          :tableDelete="true"
          :sortBy="dataBerlian.id"
          :totalKeuntunganBerlian="totalKeuntunganBerlian"
          @filterBerlian="getProductsDiamond"
          :dataPagination="dataPaginationBerlian"
          @paginate="getProductsDiamond"
        />
      </v-tab-item>
    </v-tabs-items>
  </TemplateHeader>
</template>

<script>
import TemplateHeader from "../../../layouts/TemplateHeader.vue";
import TableEmas from "../../../components/Table/TableCheckoutEmas.vue";
import TableBerlian from "../../../components/Table/TableCheckoutBerlian.vue";

import { headersEmasCheckout } from "../../../data/headersEmasCheckout";
import { headersBerlianCheckout } from "../../../data/headersBerlianCheckout";

import dataService from "../../../services/data.service";

export default {
  components: {
    TemplateHeader,
    TableEmas,
    TableBerlian,
  },
  data() {
    return {
      pagination: {
        per_page: 10,
        page: 1,
      },
      dataPaginationEmas: {},
      dataPaginationBerlian: {},
      tab: null,
      items: ["PRODUK 1", "PRODUK 2"],

      tableLoadingEmas: false,
      dataEmas: [],
      totalKeuntunganEmas: 0,
      totalKeuntunganBerlian: 0,
      headersEmasCheckout: headersEmasCheckout,
      productEmas: "gold",
      productBerlian: "diamond",
      startDate: "2022-05-01",
      endDate: "2022-07-12",

      tableLoadingBerlian: false,
      dataBerlian: [],
      headersBerlianCheckout: headersBerlianCheckout,
    };
  },
  methods: {
    async getProductsGold(params) {
      this.tableLoadingEmas = true;

      await dataService
        .getCheckout(this.productEmas, "", params)
        .then((res) => {
          this.dataEmas = res.data.data.checkout.data;
          this.totalKeuntunganEmas = res.data.data.amount_of_gold_sold;
          this.dataPaginationEmas = {
            page: res.data.data.checkout.current_page,
            itemsPerPage: parseInt(res.data.data.checkout.per_page),
            pageStart: res.data.data.checkout.from - 1,
            pageStop: res.data.data.checkout.to,
            pageCount: res.data.data.checkout.last_page,
            itemsLength: res.data.data.checkout.total,
          };
        })
        .catch((err) => {
          console.log(err);
        });
      this.tableLoadingEmas = false;
    },
    async getProductsDiamond(params) {
      this.tableLoadingBerlian = true;

      await dataService
        .getCheckout(this.productBerlian, "", params)
        .then((res) => {
          this.dataBerlian = res.data.data.checkout.data;
          this.totalKeuntunganBerlian = res.data.data.amount_of_diamond_sold;
          this.dataPaginationBerlian = {
            page: res.data.data.checkout.current_page,
            itemsPerPage: parseInt(res.data.data.checkout.per_page),
            pageStart: res.data.data.checkout.from - 1,
            pageStop: res.data.data.checkout.to,
            pageCount: res.data.data.checkout.last_page,
            itemsLength: res.data.data.checkout.total,
          };
        })
        .catch((err) => {
          console.log(err);
        });
      this.tableLoadingBerlian = false;
    },
  },
  created() {
    this.getProductsGold({ date: "", branch: "", paginate: this.pagination });
    this.getProductsDiamond({
      date: "",
      branch: "",
      paginate: this.pagination,
    });
  },
};
</script>

<style scoped>
.myHeight {
  min-height: 100vh;
}
</style>
