<template>
  <TemplateHeader :header="'Print Inventori'">
    <v-tabs v-model="tab" align-with-title active-class="mySecondary--text">
      <v-tabs-slider color="myPrimary"></v-tabs-slider>

      <v-tab v-for="item in items" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <TableEmas
          :tableLoading="tableLoadingEmas"
          :dataEmas="dataEmas"
          :headersEmas="headersEmas"
          :isDeleteChoosed="true"
          :isImportData="true"
          :isNewItem="true"
          :isPrintChoosed="true"
          :isPrintAll="true"
          :bulkAction="true"
          :tableEdit="true"
          :tableDelete="true"
          :tablePrint="true"
          :sortBy="dataEmas.id"
          :dataPagination="dataPaginationEmas"
          @paginate="getProductsGold"
        />
      </v-tab-item>
      <v-tab-item>
        <TableBerlian
          :tableLoading="tableLoadingBerlian"
          :dataBerlian="dataBerlian"
          :headersBerlian="headersBerlian"
          :isDeleteChoosed="true"
          :isImportData="true"
          :isNewItem="true"
          :isPrintChoosed="true"
          :isPrintAll="true"
          :bulkAction="true"
          :tableEdit="true"
          :tablePrint="true"
          :tableDelete="true"
          :dataPagination="dataPaginationBerlian"
          :sortBy="dataBerlian.id"
          @paginate="getProductsDiamond"
        />
      </v-tab-item>
    </v-tabs-items>
  </TemplateHeader>
</template>

<script>
import TemplateHeader from "../../layouts/TemplateHeader.vue";
import TableEmas from "../../components/Table/TableEmas.vue";
import TableBerlian from "../../components/Table/TableBerlian.vue";

import { headersEmasPrint } from "../../data/headersEmasPrint";
import { headersBerlianPrint } from "../../data/headersBerlianPrint";

import dataService from "../../services/data.service";

export default {
  components: {
    TemplateHeader,
    TableEmas,
    TableBerlian,
  },

  data() {
    return {
      tab: null,
      items: ["PRODUK 1", "PRODUK 2"],

      tableLoadingEmas: false,
      tableLoadingBerlian: false,

      dataEmas: [],
      dataBerlian: [],

      headersEmas: headersEmasPrint,
      headersBerlian: headersBerlianPrint,

      dataPaginationEmas: {},
      dataPaginationBerlian: {},
    };
  },
  methods: {
    async getProductsGold(params) {
      this.tableLoadingEmas = true;

      await dataService.getProductsGoldBeforePrint(params).then((res) => {
        this.dataEmas = res.data.data.filter(
          (data) => data.status !== "Terjual"
        );
        this.tableLoadingEmas = false;
        this.dataPaginationEmas = {
          page: res.data.current_page,
          itemsPerPage: parseInt(res.data.per_page),
          pageStart: res.data.from - 1,
          pageStop: res.data.to,
          pageCount: res.data.last_page,
          itemsLength: res.data.total,
        };
      });
    },

    async getProductsDiamond(params) {
      this.tableLoadingBerlian = true;

      await dataService.getProductsDiamondBeforePrint(params).then((res) => {
        this.dataBerlian = res.data.data.filter(
          (data) => data.status !== "Terjual"
        );
        this.tableLoadingBerlian = false;
        this.dataPaginationBerlian = {
          page: res.data.current_page,
          itemsPerPage: parseInt(res.data.per_page),
          pageStart: res.data.from - 1,
          pageStop: res.data.to,
          pageCount: res.data.last_page,
          itemsLength: res.data.total,
        };
        console.log(this.dataPaginationBerlian);
      });
    },
  },
  created() {
    this.getProductsGold();
    this.getProductsDiamond();
  },
};
</script>

<style scoped>
.btn {
  text-transform: none;
}

.myHeight {
  min-height: 100vh;
}
</style>
