var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pencarian","single-line":"","hide-details":""},model:{value:(_vm.searchRFID),callback:function ($$v) {_vm.searchRFID=$$v},expression:"searchRFID"}})],1)],1),_c('v-col',{staticClass:"align-self-center"},[_c('v-dialog',{attrs:{"width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 mb-3",attrs:{"small":"","outlined":"","color":"myPrimary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus")]),_vm._v("Impor Data ")],1)]}}]),model:{value:(_vm.dialogImport),callback:function ($$v) {_vm.dialogImport=$$v},expression:"dialogImport"}},[_c('ImportModal',{attrs:{"RFID":true},on:{"closeImporData":_vm.closeImporData}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersRFID,"items":_vm.dataRFID,"search":_vm.searchRFID,"item-key":"rfid","calculate-widths":"","loading":_vm.tableLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"550px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Apakah Anda yakin untuk menghapus RFID? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v(" TIDAK ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.confirmDelete}},[_vm._v(" YA ")])],1)],1)],1)]},proxy:true},{key:"item.number",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }