export const headersEmasCheckout = [
  { text: "Kode Barang", value: "sku", width: "100px" },
  { text: "RFID", value: "rfid", width: "120px" },
  { text: "Nama Produk", value: "product_name", width: "140px" },
  { text: "Tanggal Checkout", value: "date_order", width: "200px" },
  { text: "Gambar", value: "photo_product", width: "120px" },
  { text: "Berat", value: "weight", width: "120px" },
  { text: "Kadar", value: "purity", width: "120px" },
  { text: "Cabang", value: "branch_name", width: "130px" },
  { text: "Sub Cabang", value: "sub_branch_name", width: "220px" },
  { text: "Harga Modal", value: "price_gold", width: "120px" },
  { text: "Harga Total", value: "total_price", width: "120px" },
  { text: "Profit", value: "gold_profit", width: "120px" },
  { text: "", value: "actions", width: "90px" },
];
