<template>
  <v-card class="pa-4">
    <div class="d-flex justify-space-between align-center">
      <div class="mb-10">
        <div>Tabel Statisik Penjualan</div>
      </div>
    </div>
    <div>
      <v-row>
        <v-col cols="4">
          <v-card-title>
            <v-text-field
              v-model="searchPenjualan"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headersPenjualan"
        :items="dataPenjualan"
        :search="searchPenjualan"
        sort-by="rfid"
        :loading="tableLoading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top> </template>
        <template v-slot:[`item.image`]="{ item }">
          <div class="p-2">
            <v-img
              :src="item.photo_product"
              :alt="item.rfid"
              width="40px"
              height="40px"
              contain
            ></v-img>
          </div>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import filterService from "../../services/filter.service";

export default {
  data() {
    return {
      tableLoading: false,

      dataPenjualan: [],

      searchPenjualan: "",
      headersPenjualan: [
        { text: "Hari", value: "date" },
        {
          text: "Terjual",
          value: "emas_terjual",
          sortable: false,
          align: "center",
        },
        // {
        //   text: "Berlian Terjual",
        //   value: "berlian_terjual",
        //   sortable: false,
        //   align: "center",
        // },
      ],
    };
  },
  methods: {
    async getFilterProduct() {
      this.tableLoading = true;

      const data = null;

      await filterService.getFilterProduct(data).then((res) => {
        this.dataPenjualan = res.data;
        this.tableLoading = false;
      });
    },
  },
  created() {
    this.getFilterProduct();
  },
};
</script>
