export const headersBerlian = [
  { text: "RFID", value: "rfid" },
  { text: "Kode Barang", value: "sku", width: "120px" },
  { text: "Kode Harga", value: "design_number", width: "140px" },
  { text: "Nama Produk", value: "name", width: "130px" },
  { text: "Gambar", value: "image", width: "120px" },
  // { text: "Emas Ikat", value: "weight", width: "120px" },
  { text: "Kadar", value: "purity", width: "120px" },
  // { text: "Harga Modal", value: "capital_price_per_gram", width: "140px" },
  { text: "Harga Modal", value: "capital_diamond", width: "140px" },
  { text: "Harga Terikat", value: "price", width: "120px" },
  { text: "Catatan", value: "note", width: "120px" },
  { text: "Status", value: "status", width: "120px" },
  { text: "Cabang", value: "branch.branch_name", width: "130px" },
  { text: "Sub Cabang", value: "sub_branch.sub_branch_name", width: "220px" },
  { text: "Detail", value: "detail", width: "90px" },
  { text: "", value: "actions", width: "90px" },
];
