import api from "./apiToken";

class RfidService {
  getRFID() {
    return api.get("/getRfid");
  }
  deleteRFID(id) {
    return api.delete(`/deleteRFID/${id}`).then(() => location.reload());
  }
}

export default new RfidService();
