<template>
  <TemplateHeader :header="'Dashboard'">
    <v-row>
      <v-col cols="12" md="9">
        <div class="font-weight-medium text-body-1">Statistik Data Pusat</div>
        <v-row class="mt-8">
          <v-col cols="6" md="3" class="clickable" @click="toSold">
            <InfoSmallCard
              :header="'Produk Terjual'"
              :quantity="detailData.produk_terjual"
              :satuan="'Produk'"
              :loading="cardLoading"
            />
          </v-col>
          <v-col cols="6" md="3" class="clickable" @click="toVerification">
            <InfoSmallCard
              :header="'Produk Hilang'"
              :quantity="detailData.produk_hilang"
              :satuan="'Produk'"
              :loading="cardLoading"
            />
          </v-col>
          <v-col cols="6" md="3" class="clickable" @click="toBerlianTerjual">
            <InfoSmallCard
              :header="'Produk 1 Terjual'"
              :quantity="detailData.berlian_terjual"
              :satuan="'Produk'"
              :loading="cardLoading"
            />
          </v-col>
          <v-col cols="6" md="3" class="clickable" @click="toEmasTerjual">
            <InfoSmallCard
              :header="'Produk 2 Terjual'"
              :quantity="detailData.emas_terjual"
              :satuan="'Produk'"
              :loading="cardLoading"
            />
          </v-col>
          <v-col cols="6" md="3" class="clickable" @click="toBerlianTersedia">
            <InfoSmallCard
              :header="'Stok Produk 1'"
              :quantity="detailData.berlian_tersedia"
              :loading="cardLoading"
            />
          </v-col>
          <v-col cols="6" md="3" class="clickable" @click="toEmasTersedia">
            <InfoSmallCard
              :header="'Stok Produk 2'"
              :quantity="detailData.emas_tersedia"
              :loading="cardLoading"
            />
          </v-col>
          <!-- <v-col cols="6" md="3">
            <InfoSmallCard
              :header="'Keuntungan Produk 1'"
              :quantity="detailData.keuntungan_emas"
              :satuan="'gram'"
              :loading="cardLoading"
            />
          </v-col> -->
          <v-col cols="6" md="3">
            <InfoSmallCard
              :header="'Keuntungan Produk'"
              :quantity="parseRupiah(detailData.keuntungan_berlian)"
              :loading="cardLoading"
            />
          </v-col>
          <!-- <v-col cols="6" md="3">
            <InfoSmallCard
              :header="'Emas Ikat Berlian'"
              :quantity="detailData.berat_emas_terikat"
              :satuan="'gram'"
              :loading="cardLoading"
            />
          </v-col>
          <v-col cols="6" md="3">
            <InfoSmallCard
              :header="'Harga Berlian Terikat'"
              :quantity="parseRupiah(detailData.harga_berlian_terikat)"
              :satuan="''"
              :loading="cardLoading"
            />
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card height="auto" :loading="cardLoading">
              <div class="pa-4">
                <div class="d-flex justify-space-between align-center mb-4">
                  <div>Pesanan Terbaru</div>
                  <div>
                    <v-btn to="/sold" small color="mySecondary white--text btn">
                      Lihat Lainnya
                    </v-btn>
                  </div>
                </div>
                <div>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Tanggal</th>
                          <th class="text-left">RFID</th>
                          <th class="text-left">Kode Barang</th>
                          <th class="text-left">Nama Produk</th>
                          <th class="text-left">Cabang</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in detailData.produk_terjual_terbaru"
                          :key="item.rfid"
                        >
                          <td>{{ item.date_order }}</td>
                          <td>{{ item.rfid }}</td>
                          <td>{{ item.sku }}</td>
                          <td>{{ item.name }}</td>
                          <td>{{ item.branch.branch_name }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <TablePenjualan />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="3">
        <v-card elevation="0" :loading="cabangLoading">
          <div class="pa-4">
            <div class="font-weight-medium text-body-1">Penjualan Cabang</div>

            <v-card
              v-for="cabang in dataCabang"
              :key="cabang.id"
              :to="'/dashboard/' + cabang.id"
              link
              class="pa-2 my-4"
            >
              <div class="d-flex align-center py-2">
                <div>
                  <v-icon
                    class="myPrimary lighten-2 white--text pa-1 rounded-sm"
                    size="15"
                  >
                    mdi-store-outline
                  </v-icon>
                </div>
                <div class="text-body-2 font-weight-medium ml-4">
                  {{ cabang.branch_name }}
                </div>
              </div>
            </v-card>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </TemplateHeader>
</template>

<script>
import TemplateHeader from "../../../layouts/TemplateHeader.vue";
import InfoSmallCard from "../../../components/Card/InfoSmallCard.vue";

import dataService from "../../../services/data.service";

import TablePenjualan from "../../../components/Table/TablePenjualan.vue";

export default {
  components: {
    InfoSmallCard,
    TablePenjualan,
    TemplateHeader,
  },
  data() {
    return {
      cardLoading: false,
      cabangLoading: false,

      detailData: {},
      dataCabang: [],
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" s/d ");
    },
  },
  methods: {
    toSold() {
      this.$router.push("/sold");
    },
    toVerification() {
      this.$router.push("/verification");
    },
    toEmasTerjual() {
      this.$router.push("/sold-gold");
    },
    toBerlianTerjual() {
      this.$router.push("/sold-diamond");
    },
    toEmasTersedia() {
      this.$router.push("/stock-gold");
    },
    toBerlianTersedia() {
      this.$router.push("/stock-diamond");
    },
    async getProductsSoldDetailPusat() {
      this.cardLoading = true;

      await dataService.getProductSoldDetailPusat().then((res) => {
        this.detailData = res.data;
        this.cardLoading = false;
      });
    },

    parseRupiah(price) {
      if (price == null) {
        return "";
      } else {
        return "Rp. " + "5.015.000";
      }
    },

    async getCabang() {
      this.cabangLoading = true;

      await dataService
        .getCabang()
        .then((res) => {
          this.dataCabang = res.data;
          this.cabangLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            location.reload();
          }
        });
    },
  },
  created() {
    this.getProductsSoldDetailPusat();
    this.getCabang();
  },
};
</script>

<style scoped>
.clickable:hover {
  cursor: pointer;
}
.btn {
  text-transform: none;
}
.btnWeigth {
  font-weight: 900;
}
.myHeight {
  min-height: 100vh;
}
</style>
