import api from "./apiToken";

class DataService {
  // INVENTORY
  deleteSelectedProduct(data) {
    return api.post(`/bulkDelete`, data);
  }
  printAllProduct(data) {
    return api.post(`/printProduct`, data);
  }
  deleteSingleProduct(id) {
    return api.delete(`/deleteProduct/${id}`);
  }
  printSelectedProduct(data) {
    return api.post(`/printProduct`, data);
  }

  updateGold(id, data) {
    return api.post(`/updateGold/${id}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  updateDiamond(id, data) {
    return api.post(`/updateDiamond/${id}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  importRFID(data) {
    return api.post("/importRFID", data);
  }
  importGold(data) {
    return api.post("/importGold", data);
  }
  importDiamond(data) {
    return api.post("/importDiamond", data);
  }

  postNewBerlian(data) {
    return api.post("/postDiamond", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  postNewGold(data) {
    return api.post("/postGold", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  // PUSAT
  getProductSoldDetailPusat() {
    return api.get("/getProductsSoldDetailPusat");
  }
  getProductGold(params) {
    return api.get("/getProductsGold", { params });
  }
  getProductsDiamond(params) {
    return api.get("/get-products-diamond-avail", { params });
  }
  getProductsGoldBeforePrint(params) {
    return api.get("/getProductsGoldBeforePrint", { params });
  }
  getProductsDiamondBeforePrint(params) {
    return api.get("/getProductsDiamondBeforePrint", { params });
  }
  getSoldProduct(pathParams, params) {
    return api.post(
      "/sold-product" + (pathParams ? `/${pathParams}` : ""),
      null,
      { params }
    );
  }

  // CABANG
  getListCabang() {
    return api.get("/role");
  }
  deleteCabang(id) {
    return api.delete(`/role/delete/${id}`);
  }
  updateCabang(id, data) {
    return api.post(`/role/update/${id}`, data);
  }
  postNewCabang(data) {
    return api.post("/role/store", data);
  }
  getProductSoldBranchDetail(id) {
    return api.get(`getProductsSoldBranchDetail/${id}`);
  }
  getProductsBranchGold(id) {
    return api.get(`/gold-base-branch/${id}`);
  }
  getProductsBranchDiamond(id) {
    return api.get(`/diamond-base-branch/${id}`);
  }

  // SUBCABANG
  getListSubCabang() {
    return api.get("/role/sub-branch");
  }
  deleteSubCabang(id) {
    return api.delete(`/role/delete-sub-branch/${id}`);
  }
  updateSubCabang(id, data) {
    return api.post(`/role/update-sub-branch/${id}`, data);
  }
  postNewSubCabang(data) {
    return api.post("/role/store-sub-branch", data);
  }
  getProductsSubBranchDiamond(id) {
    return api.get(`/inventory-sub-branch/get-diamond/${id}`);
  }
  getProductSoldSubBranchDetail(id) {
    return api.get(`/sub-branch-detail/${id}`);
  }
  getProductsSubBranchGold(id) {
    return api.get(`/inventory-sub-branch/get-gold/${id}`);
  }
  getSubCabangDetail(id) {
    return api.get(`/sub-branch/detail/${id}`);
  }

  // LIST DATA - DROPDOWN
  getCabang() {
    return api.get("/getCabang");
  }
  getSubCabang(id) {
    return api.get(`/sub-branch/${id}`);
  }
  getAllSubCabang() {
    return api.get(`/sub-branch`);
  }

  // LIST CHECKOUT
  getCheckout(product, pathParams, params) {
    return api.get(
      "/checkout-history/" + product + (pathParams ? `/${pathParams}` : ""),
      { params }
    );
  }
  // updateCheckout(product, id, data) {
  //   return api.put(`/checkout-history/${product}/${id}`, data)
  // }
  updateCheckout(product, checkout_id, product_id, data) {
    return api.put(
      `/checkout-history/${product}/${checkout_id}/${product_id}?_method=PUT`,
      data
    );
  }
  deleteCheckout(id) {
    return api.delete(`/checkout-history/${id}`);
  }
}

export default new DataService();
