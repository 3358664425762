<template>
  <TemplateHeader :header="'List RFID'">
    <v-card class="pa-4">
      <div class="d-flex justify-space-between align-center">
        <div class="mb-10">
          <div class="font-weight-medium">Harga</div>
        </div>
      </div>

      <div v-if="!goldPrice" class="mt-2">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form
            @submit.prevent="submit"
            class="d-flex justify-space-between align-center"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Harga Emas"
              rules="required"
            >
              <v-text-field
                label="Harga Emas"
                v-model="goldPriceValue"
                :error-messages="errors"
                :rules="[numberRule]"
                prefix="Rp."
              ></v-text-field>
            </validation-provider>
            <v-btn
              color="myPrimary white--text"
              class="btn"
              :disabled="invalid"
              @click="postGoldPrice"
              >Buat Harga</v-btn
            >
          </form>
        </validation-observer>
      </div>

      <div v-if="goldPrice" class="mt-2">
        <validation-observer v-if="EDIT" ref="observer" v-slot="{ invalid }">
          <form
            @submit.prevent="submit"
            class="d-flex justify-space-between align-center"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Harga Emas"
              rules="required"
            >
              <v-text-field
                label="Harga Emas"
                v-model="goldPriceValue"
                :error-messages="errors"
                :rules="[numberRule]"
                prefix="Rp."
              ></v-text-field>
            </validation-provider>
            <div class="d-flex justify-space-between align-center">
              <v-btn
                color="myRed white--text"
                class="btn mr-2"
                @click="EDIT = false"
              >
                Cancel
              </v-btn>

              <v-btn
                color="myPrimary white--text"
                class="btn"
                :loading="loading"
                :disabled="invalid"
                @click="updateGoldPrice"
                >Confirm</v-btn
              >
            </div>
          </form>
        </validation-observer>

        <div v-if="!EDIT" class="d-flex justify-space-between align-center">
          <div class="font-weight-medium text-h5">{{ goldPrice.price }}</div>
          <div class="d-flex justify-space-between align-center">
            <v-btn
              color="myPrimary white--text"
              class="btn"
              @click="EDIT = true"
              >Update</v-btn
            >
          </div>
        </div>
      </div>
    </v-card>
  </TemplateHeader>
</template>

<script>
import TemplateHeader from "../../layouts/TemplateHeader.vue";
import goldPriceService from "../../services/goldPrice.service";
import { required } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  setInteractionMode,
  ValidationObserver,
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  components: {
    TemplateHeader,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      goldPrice: {},
      goldPriceValue: "",
      EDIT: false,
    };
  },
  methods: {
    numberRule(v) {
      if (v != undefined) {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0) return true;
        return "Number must greater than zero (0)";
      } else {
        return true;
      }
    },
    async getGoldPrice() {
      this.loading = true;

      await goldPriceService
        .getGoldPrice()
        .then((res) => {
          this.goldPrice = res.data.data;
        })
        .catch((err) => console.log(err));

      this.loading = false;
    },
    async postGoldPrice() {
      this.loading = true;

      const data = {
        price: parseInt(this.goldPriceValue),
      };

      await goldPriceService
        .postGoldPrice(data)
        .then(() => location.reload())
        .catch((err) => console.log(err));

      this.loading = false;
    },
    async updateGoldPrice() {
      this.loading = true;

      const data = {
        id: this.goldPrice.id,
        price: parseInt(this.goldPriceValue),
      };

      await goldPriceService
        .updateGoldPrice(data)
        .then(() => location.reload())
        .catch((err) => console.log(err));

      this.loading = false;
    },
  },
  created() {
    this.getGoldPrice();
  },
};
</script>

<style scoped>
.btn {
  text-transform: none;
}
</style>
