<template>
  <TemplateHeader :header="'Inventory'">
    <v-tabs v-model="tab" align-with-title active-class="mySecondary--text">
      <v-tabs-slider color="myPrimary"></v-tabs-slider>

      <v-tab v-for="item in items" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <TableEmas
          :tableLoading="tableLoadingEmas"
          :dataEmas="dataEmas"
          :headersEmas="headersEmas"
          :isDeleteChoosed="true"
          :bulkAction="true"
          :tableEdit="true"
          :tableDelete="true"
          :sortBy="dataEmas.id"
          :dataPagination="dataPaginationEmas"
          @paginate="getProductsGold"
        />
      </v-tab-item>
      <v-tab-item>
        <TableBerlian
          :tableLoading="tableLoadingBerlian"
          :dataBerlian="dataBerlian"
          :headersBerlian="headersBerlian"
          :isDeleteChoosed="true"
          :bulkAction="true"
          :tableEdit="true"
          :tableDelete="true"
          :dataPagination="dataPaginationBerlian"
          :sortBy="dataBerlian.id"
          @paginate="getProductsDiamond"
        />
      </v-tab-item>
    </v-tabs-items>
  </TemplateHeader>
</template>

<script>
import TemplateHeader from "../../../layouts/TemplateHeader.vue";
import TableEmas from "../../../components/Table/TableEmas.vue";
import TableBerlian from "../../../components/Table/TableBerlian.vue";

import { headersEmas } from "../../../data/headersEmas";
import { headersBerlian } from "../../../data/headersBerlian";

import dataService from "../../../services/data.service";

export default {
  components: {
    TemplateHeader,
    TableEmas,
    TableBerlian,
  },
  data() {
    return {
      pagination: {
        per_page: 10,
        page: 1,
      },
      dataPaginationEmas: {},
      dataPaginationBerlian: {},
      tab: null,
      items: ["PRODUK 1", "PRODUK 2"],

      tableLoadingEmas: false,
      dataEmas: [],
      headersEmas: headersEmas,

      tableLoadingBerlian: false,
      dataBerlian: [],
      headersBerlian: headersBerlian,
    };
  },
  methods: {
    async getProductsGold(params) {
      this.tableLoadingEmas = true;

      await dataService.getProductGold(params).then((res) => {
        const dataEmas = res.data.data;

        this.dataEmas = dataEmas.filter((data) => data.status != "Terjual");
        this.dataPaginationEmas = {
          page: res.data.current_page,
          itemsPerPage: parseInt(res.data.per_page),
          pageStart: res.data.from - 1,
          pageStop: res.data.to,
          pageCount: res.data.last_page,
          itemsLength: res.data.total,
        };

        this.tableLoadingEmas = false;
      });
    },
    async getProductsDiamond(params) {
      this.tableLoadingEmas = true;

      await dataService.getProductsDiamond(params).then((res) => {
        this.dataBerlian = res.data.data.filter(
          (data) =>
            data.status === "Tersedia" ||
            data.status === "Belum Terpindai" ||
            data.status === "Pindah Cabang" ||
            data.status === "Perbaikan"
        );
        this.dataPaginationBerlian = {
          page: res.data.current_page,
          itemsPerPage: parseInt(res.data.per_page),
          pageStart: res.data.from - 1,
          pageStop: res.data.to,
          pageCount: res.data.last_page,
          itemsLength: res.data.total,
        };
        this.tableLoadingEmas = false;
      });
    },
  },
  mounted() {
    this.getProductsGold(this.pagination);
    this.getProductsDiamond(this.pagination);
  },
};
</script>

<style scoped>
.myHeight {
  min-height: 100vh;
}
</style>
