export const headersEmas = [
  { text: "RFID", value: "rfid" },
  { text: "Kode Barang", value: "sku", width: "100px" },
  { text: "Nama Produk", value: "name", width: "140px" },
  { text: "Gambar", value: "image", width: "120px" },
  // { text: "Harga Modal Emas", value: "price_gold", width: "150px" },
  { text: "Berat", value: "weight", width: "120px" },
  { text: "Jenis", value: "type", width: "120px" },
  { text: "Kadar", value: "purity", width: "120px" },
  { text: "Kualitas", value: "quality", width: "120px" },
  { text: "Kondisi", value: "condition", width: "120px" },
  { text: "Catatan", value: "note", width: "120px" },
  { text: "Cabang", value: "branch.branch_name", width: "130px" },
  { text: "Sub Cabang", value: "sub_branch.sub_branch_name", width: "220px" },
  { text: "Status", value: "status", width: "120px" },
  { text: "Detail", value: "detail", width: "90px" },
  { text: "", value: "actions", width: "90px" },
];
